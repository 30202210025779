type colorType = {
  [key: string]: string
}
export const useColor = (list: any) => {
  const colors = ['#FF5E29', '#4643FF', '#00C0C7', '#FF2FDC']
  const colorObj = JSON.parse(
    localStorage.getItem('colorObj') || '{}'
  ) as colorType
  list.map((v: { operatorAccount: string, color: string }) => {
    if (colorObj[v.operatorAccount]) {
      v.color = colorObj[v.operatorAccount]
    } else if (!colorObj[v.operatorAccount]) {
      const values = Object.values(colorObj)
      colors.forEach(item => {
        if (!values.includes(item)) {
          colorObj[v.operatorAccount] = item
          v.color = colorObj[v.operatorAccount]
        }
      })
    }
  })
  console.log(colorObj, 'obj')
  localStorage.setItem('colors', JSON.stringify(colorObj))
}
