import { useLazyQuery } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ref } from 'vue'
import _ from 'lodash'

export default function useOrder(data: any) {
  const { load, loading, onResult, refetch } = useLazyQuery(gql.lpoaOrderInfo)

  const orderInfotableData = ref()
  onResult(res => {
    console.log('res', res)
    if (res?.data) {
      orderInfotableData.value = _.cloneDeep(res?.data.lpoaOrderInfo.data)
      orderInfotableData.value.subOrders = res?.data.lpoaOrderInfo.data.subOrders.map(
        (item: any) => {
          return {
            ...item,
            nameCn:
            data.value[0]?.bindInfo.filter(
                (ele: any) => ele.account === item.fundAccount
              )[0]?.nameCn ?? '--'
          }
        }
      )
    }
  })
  return {
    load,
    orderLoading: loading,
    orderRefetch: refetch,
    orderInfotableData
  }
}
