import { useQuery } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ref } from 'vue'
import { calcSum, getFundList } from './index'
import { arrSum } from '@/utils/num_format'
import _ from 'lodash'
import { useStore } from 'vuex'
import { CommonMutationTypes } from '@/store/modules/common/mutation-types'
import { useColor } from './useColor'

export default function useData () {
  const store = useStore()
  // 用户资金
  const fundList = ref()
  const fList = ref()
  const posList: any = ref([])
  const accList = ref()
  const assetList = ref()
  const orderList = ref()
  const summaryList = ref()

  const params = {
    lpoaSummary: {
      startDate: '',
      endDate: ''
    },
    filterOrderList: {
      startDate: '',
      endDate: ''
    }
  }
  const { onResult, loading, refetch } = useQuery(gql.queryHomeData, params)

  onResult(res => {
    const {
      lpoaForex,
      lpoaFundInfoList,
      lpoaOrderList,
      lpoaPositionList,
      lpoaSummary,
      lpoaManagersBindInfoList
    }: any = res.data
    const fund = getFundList(lpoaFundInfoList.data)
    const positionIncome = calcSum(lpoaPositionList.data, 'positionIncome')
    fundList.value = {
      ...fund,
      positionIncome: positionIncome
    }
    fList.value = lpoaFundInfoList.data
    accList.value = lpoaManagersBindInfoList.data
    lpoaPositionList.data.forEach((item: any) => {
      if (item.info.rows.length > 0) {
        const rows = item.info.rows
        posList.value.push(...rows)
      }
      if (item.structuredFundPositionInfo.rows.length > 0) {
        const structuredFundPositionInfo = item.structuredFundPositionInfo.rows
        posList.value.push(...structuredFundPositionInfo)
      }
    })
    assetList.value = arrSum(_.cloneDeep(posList.value))
    console.log('assetList', posList.value)

    // const rate = lpoaForex.data.filter((item: any) => item.ccy === 'USD')[0]
    //   .rate
    store.commit(CommonMutationTypes.SET_EXCHANGE_RATE, lpoaForex.data)

    // 下单人颜色
    orderList.value = lpoaOrderList.rows
    useColor(lpoaOrderList.rows)
    console.log(orderList.value, 'orderList')

    summaryList.value = lpoaSummary.data
  })
  return {
    loading,
    refetch,
    fundList,
    fList,
    posList,
    accList,
    assetList,
    orderList,
    summaryList
  }
}
