
import { computed, defineComponent, reactive, watchEffect } from 'vue'
import up from '@/assets/images/home/up.svg'
import down from '@/assets/images/home/down.svg'
import diamond from '@/assets/images/home/diamond.svg'
import { calcByMktStr } from '../hooks'
import { useCommonInfo } from '@/hooks/common'
import Decimal from 'decimal.js'
import { numFormat } from '@/utils/num_format'
import { ElMessageBox } from 'element-plus'

export default defineComponent({
  props: {
    list: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    summaryList: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { moneyType, exchangeRate } = useCommonInfo()

    // const { summaryList } = useSummary()
    const state: any = reactive({
      list: {}
    })
    watchEffect(() => {
      state.list = props.list
      // console.log(state.list, '接受的list')
    })
    // 总资产
    const totalAsset = computed(() =>
      calcByMktStr(state.list?.asset, exchangeRate.value, moneyType.value)
    )
    const yestAsset = computed(() =>
      calcByMktStr(
        props.summaryList[0]?.asset,
        exchangeRate.value,
        moneyType.value
      )
    )
    const assetLimit = computed(() => {
      if (!props.summaryList[0]) return '--'
      if (
        new Decimal(state.list?.asset ?? 0)
          .sub(props.summaryList[0]?.asset ?? 0)
          .isZero()
      ) {
        return '--'
      } else if (Number(Decimal.abs(props.summaryList[0]?.asset ?? 0)) === 0) {
        return '--'
      } else {
        return new Decimal(state.list?.asset ?? 0)
          .sub(props.summaryList[0]?.asset ?? 0)
          .div(Decimal.abs(props.summaryList[0]?.asset ?? 0))
          .mul(100)
          .toFixed(2, Decimal.ROUND_DOWN)
      }
    })
    // 总购买力
    const enableBalance = computed(() =>
      calcByMktStr(
        state.list?.enableBalance,
        exchangeRate.value,
        moneyType.value
      )
    )
    const enableBalanceHK = computed(() => state.list?.enableBalanceHK)
    const enableBalanceUS = computed(() => state.list?.enableBalanceUS)
    const enableBalanceCN = computed(() => state.list?.enableBalanceCN)
    // 客户数
    const customers = computed(() => state.list?.customers)
    const yestCustomers = computed(
      () => props.summaryList[0]?.childaccountNum ?? 0
    )
    const customersLimit = computed(() =>
      (customers.value - yestCustomers.value).toString()
    )
    // 盈亏
    const positionIncome = computed(() =>
      calcByMktStr(
        state.list?.positionIncome,
        exchangeRate.value,
        moneyType.value
      )
    )
    const yestDailyIncome = computed(() =>
      calcByMktStr(
        props.summaryList[0]?.positionIncome,
        exchangeRate.value,
        moneyType.value
      )
    )

    const dailyIncomeLimt = computed(() => {
      if (!props.summaryList[0]) return '--'
      if (
        new Decimal(state.list?.positionIncome ?? 0)
          .sub(props.summaryList[0]?.positionIncome ?? 0)
          .isZero()
      ) {
        return '--'
      } else if (
        Number(Decimal.abs(props.summaryList[0]?.positionIncome ?? 0)) === 0
      ) {
        return '--'
      } else {
        return new Decimal(state.list?.positionIncome ?? 0)
          .sub(props.summaryList[0]?.positionIncome ?? 0)
          .div(Decimal.abs(props.summaryList[0]?.positionIncome ?? 0))
          .mul(100)
          .toFixed(2, Decimal.ROUND_DOWN)
      }
    })

    const data = reactive({
      totalAsset: {
        title: '总资产',
        asset: totalAsset,
        yesterdayData: yestAsset ?? '--',
        limit: assetLimit
      },
      client: {
        title: '客户数量',
        asset: customers,
        yesterdayData: yestCustomers,
        limit: customersLimit,
        isCustomer: true
      },
      profit: {
        title: '总盈亏',
        asset: positionIncome,
        yesterdayData: yestDailyIncome ?? '--',
        limit: dailyIncomeLimt,
        isProfit: true
      },
      purchase: {
        title: '总购买力',
        asset: enableBalance,
        HKD: enableBalanceHK,
        USD: enableBalanceUS,
        CNY: enableBalanceCN,
        isEnable: true
      }
    }) as any

    const showToolTip = (val: string) => {
      let message = `「${val}」仅供参考，该数据或未能反映帐户的实时${val}，以及或未包含其他影响${val}的收入和费用等。阁下任何依赖或就该数据所作的任何决定，金马证券概不负责。请联系客户服务部（cs@igoldhorse.com）垂询实际「${val}」。`
      if (val.includes('盈亏')) {
        message = `「${val}」仅供参考，该数据或未能反映整体持仓的实时盈亏。阁下任何依赖或就该数据所作的任何决定，金马证券概不负责。请联系客户服务部（cs@igoldhorse.com）垂询实际「${val}」。`
      }
      ElMessageBox.alert(message, '免责声明', {
        confirmButtonText: '我知道了',
        customClass: 'message-info',
        showClose: false,
        center: true,
        type: 'warning',
        callback: () => {
          console.log('aaaa')
        }
      })
    }
    return {
      // ...toRefs(state),
      up,
      down,
      diamond,
      data,
      numFormat,
      showToolTip
    }
  }
})
