import {
  lpoaManagersBindInfoList,
  lpoaOrderInfo,
  lpoaOrderList,
  lpoaSummary
} from '@/graphql'
import { useLazyQuery, useQuery, useResult } from '@vue/apollo-composable'
import { computed, ref } from 'vue'
import { Decimal } from 'decimal.js'
import _ from 'lodash'

export const usePosMap = (arr: any, key: string) => {
  const filArrMpp = new Map()
  arr.forEach((v: any) => {
    if (!filArrMpp.has(v[key])) {
      filArrMpp.set(v[key], [v])
    } else {
      filArrMpp.get(v[key]).push(v)
    }
  })
  return filArrMpp
}

/**
 * 计算默认HK情况下的值随市场变化
 */
export const calcByMktStr = (
  val: string,
  exchangeRate: number,
  moneyType: string
) => {
  switch (moneyType) {
    case 'HKD':
      return new Decimal(parseFloat(val ?? '0.0')).toFixed(
        2,
        Decimal.ROUND_DOWN
      )
    case 'USD':
      return new Decimal(parseFloat(val ?? '0.0'))
        .div(exchangeRate)
        .toFixed(2, Decimal.ROUND_DOWN)
    case 'CNY':
      return new Decimal(parseFloat(val ?? '0.0'))
        .div(exchangeRate)
        .toFixed(2, Decimal.ROUND_DOWN)
    default:
      break
  }
}
/**
 * HK换算成US US换算HK
 */

export const HKToUS = (
  HKVal: string,
  USVal: string,
  exchangeRate: number,
  moneyType: string
) => {
  return moneyType === 'HKD'
    ? new Decimal(parseFloat(USVal ?? '0.0'))
        .mul(exchangeRate)
        .plus(parseFloat(HKVal ?? '0.0'))
        .toFixed(2, Decimal.ROUND_DOWN)
    : new Decimal(parseFloat(USVal ?? '0.0'))
        .div(exchangeRate)
        .plus(parseFloat(HKVal ?? '0.0'))
        .toFixed(2, Decimal.ROUND_DOWN)
}

export const calcByMkt = (
  exchangeType: String,
  exchangeRate: number,
  HKList: Array<any>,
  USList: Array<any>,
  key: string
) => {
  const HKData = HKList.reduce((pre: any, cur: any) => {
    return new Decimal(pre).plus(Number(cur[key])).toNumber()
  }, 0)
  const USData = USList.reduce((pre: any, cur: any) => {
    return new Decimal(pre).plus(Number(cur[key])).toNumber()
  }, 0)
  if (exchangeType === 'HK') {
    return new Decimal(USData)
      .mul(exchangeRate)
      .plus(HKData)
      .toFixed(2, Decimal.ROUND_DOWN)
  } else {
    return new Decimal(HKData)
      .div(exchangeRate)
      .plus(USData)
      .toFixed(2, Decimal.ROUND_DOWN)
  }
}

export const calcSum = (list: any, key: string) => {
  return list.reduce((pre: any, cur: any) => {
    return new Decimal(pre).plus(Number(cur[key])).toNumber()
  }, 0)
}

export const filterStock = (stockCode: string, list = []) => {
  const pList = list.filter((item: any) => item.stockCode === stockCode)
  return pList
}

// 交易数据
export const getFundList = (list: any) => {
  // 总资产 按HK算的
  const asset = calcSum(list, 'asset')
  // 盈亏
  // const dailyIncome = calcSum(list, 'dailyIncome')
  // 总购买力 按HK算的
  const enableBalance = calcSum(list, 'enableBalance')
  const allInfoList: any = ref([])
  list.forEach((item: any) => {
    allInfoList.value.push(...item.info)
  })
  const HKList = allInfoList.value?.filter(
    (item: any) => item.exchangeType === 'HK'
  )
  const USList = allInfoList.value?.filter(
    (item: any) => item.exchangeType === 'US'
  )
  const CNList = allInfoList.value?.filter(
    (item: any) => item.exchangeType === 'A'
  )

  const enableBalanceHK = calcSum(HKList, 'enableBalance')
  const enableBalanceUS = calcSum(USList, 'enableBalance')
  const enableBalanceCN = calcSum(CNList, 'enableBalance')
  // 可取现金
  const fetchBalance = calcSum(list, 'fetchBalance')
  const fetchBalanceHK = calcSum(HKList, 'fetchBalance')
  const fetchBalanceUS = calcSum(USList, 'fetchBalance')
  const fetchBalanceCN = calcSum(CNList, 'fetchBalance')
  // 市值
  const marketValueHK = calcSum(HKList, 'marketValue')
  const marketValueUS = calcSum(USList, 'marketValue')
  const marketValueCN = calcSum(CNList, 'marketValue')
  // 总市值 按HK算的
  const marketValue = calcSum(list, 'marketValue')
  // 客户数
  const customers = list?.length

  return {
    asset,
    enableBalance,
    enableBalanceHK,
    enableBalanceUS,
    enableBalanceCN,
    fetchBalance,
    fetchBalanceHK,
    fetchBalanceUS,
    fetchBalanceCN,
    marketValueHK,
    marketValueUS,
    marketValueCN,
    marketValue,
    customers
  }
}

export const useSummary = () => {
  const params = {
    filter: {
      // startDate: dayjs(new Date().getTime() - 24 * 60 * 60 * 1000).format('YYYYMMDD'),
      // endDate: dayjs(new Date().getTime() - 24 * 60 * 60 * 1000).format('YYYYMMDD')
      startDate: '',
      endDate: ''
    }
  }
  const { result } = useQuery(lpoaSummary, params, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })
  const hisList: any = useResult(result, [], data => data.lpoaSummary.data)
  const summaryList = computed(() => {
    return hisList.value.map((item: any) => item)
  })
  return { summaryList }
}

// lpoa订单
export const useLpoaOrderList = () => {
  const lpoaOrderListParams = ref({
    filter: {
      // startDate: Number(dayjs(new Date()).format('YYYYMMDD')),
      // endDate: Number(dayjs(new Date()).format('YYYYMMDD'))
      startDate: '',
      endDate: '',
      pageNo: 1,
      pageSize: 100
    }
  })
  const { result } = useQuery(lpoaOrderList, lpoaOrderListParams, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })
  const ordList = useResult(result, [], data => data.lpoaOrderList.rows)
  const orderList = computed(() => {
    return ordList.value.map((item: any) => item)
  })
  return {
    orderList
  }
}

// lpoa订单详情
export const useLpoaOrderInfo = (directiveid: number) => {
  const { load: queryOrder, result } = useLazyQuery(
    lpoaOrderInfo,
    {
      filter: {
        directiveid
      }
    },
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }
  )
  const ordList: any = useResult(result, [], data => data.lpoaOrderInfo.data)

  const orderInfoList = computed(() => {
    return _.cloneDeep(ordList.value)
  })

  return {
    queryOrder,
    orderInfoList
  }
}

/**
 * 获取客户名字
 */
export const useAccountName = () => {
  const { result } = useQuery(lpoaManagersBindInfoList, null, {
    fetchPolicy: 'no-cache'
  })
  const accList: any = useResult(
    result,
    {},
    data => data.lpoaManagersBindInfoList.data
  )
  const accountList = computed(() => {
    return _.cloneDeep(accList.value)
  })
  return {
    accountList
  }
}

export function sortKey (array: any, key: string) {
  return array.sort(function (a: any, b: any) {
    let x = a[key].substring(0, a[key].length - 1)
    let y = b[key].substring(0, b[key].length - 1)
    if (!isNaN(Number(x)) && !isNaN(Number(y))) {
      x = Number(x)
      y = Number(y)
    }
    return x > y ? -1 : x < y ? 1 : 0
  })
}
