
import {
  computed,
  defineComponent,
  reactive,
  Ref,
  ref,
  watchEffect
} from 'vue'
import {
  assetTableHead,
  assetDetailTableHead,
  fundDetailTableHead
} from '../hooks/data'
import { calcByMktStr, calcSum, filterStock, sortKey } from '../hooks/index'
import { useRouter } from 'vue-router'
import _ from 'lodash'
import { useCommonInfo } from '@/hooks/common'
import Decimal from 'decimal.js'
import { numFormat } from '@/utils/num_format'
import { useStore } from 'vuex'
import { profitColor } from '@/utils/color'

export default defineComponent({
  name: 'AssetOverview',
  props: {
    assetList: {
      type: Array,
      default: () => []
    },
    posList: {
      type: Array,
      default: () => []
    },
    fund: {
      type: Object,
      default: () => ({})
    },
    accList: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { moneyType, exchangeRate } = useCommonInfo()
    const router = useRouter()
    const store = useStore()
    const popoverRef: Ref<any> = ref(null)
    const popoverTop = ref(0)
    const arrowTop = ref(60)
    // const arrowRight = computed(() => popoverRef.value?.offsetWidth + 12)
    const rowIndex = ref(0)
    const rowRecord = ref({
      exchangeType: ''
    })
    const isShowRowTable = ref(false)
    // const assetDetailTableData: Ref<any> = ref()

    // 资产表格重写
    const state: any = reactive({
      assetList: [],
      posList: [],
      list: [],
      accList: [],
      fund: {}
    })
    watchEffect(() => {
      state.assetList = props.assetList
      state.posList = props.posList
      state.list = props.list
      state.fund = props.fund
      state.accList = props.accList
      console.log(state.assetList, 'state.assetList')
      console.log(props.list, '接受的list')
    })
    const fundName = computed(() => `现金(${moneyType.value})`)
    const totalName = computed(() => `总计(${moneyType.value})`)
    const fetchBalance = computed(() =>
      calcByMktStr(
        state.fund?.fetchBalance,
        exchangeRate.value,
        moneyType.value
      )
    )
    const percent = computed(() =>
      state.fund?.asset
        ? parseFloat(
            new Decimal(state.fund?.fetchBalance)
              .div(parseFloat(state.fund?.asset ?? '0.0'))
              .mul(100)
              .toFixed(2, Decimal.ROUND_DOWN)
          ) + '%'
        : '--' + '%'
    )
    const enableAmountSum = computed(() =>
      numFormat(calcSum(state.assetList, 'enablePosNum'), -1)
    )
    const currentAmountSum = computed(() =>
      numFormat(calcSum(state.assetList, 'enablePosNum'), -1)
    )
    const assetTableHKData = computed(() =>
      state.assetList.filter((item: any) => item.moneyType === 'HKD')
    )
    const assetTableUSData = computed(() =>
      state.assetList.filter((item: any) => item.moneyType === 'USD')
    )
    const assetTableCNData = computed(() =>
      state.assetList.filter((item: any) => item.moneyType === 'CNY')
    )
    const exchangeRateCN = store.getters.getCommonInfo.exchangeRate.filter(
      (el: { ccy: string }) => el.ccy === 'CNY'
    )[0].rate
    const exchangeRateUS = store.getters.getCommonInfo.exchangeRate.filter(
      (el: { ccy: string }) => el.ccy === 'USD'
    )[0].rate
    const profitHK = computed(() => calcSum(assetTableHKData.value, 'profit'))
    const profitUS = computed(() => calcSum(assetTableUSData.value, 'profit'))
    const profitCN = computed(() => calcSum(assetTableCNData.value, 'profit'))
    // const marketValSum = computed(() => {
    //   const mktHK = new Decimal(props.fund.marketValue || 0)
    //     .plus(state.fund?.fetchBalance || 0)
    //     .toString()
    //   console.log(mktHK, 'mktHK')
    //   return calcByMktStr(mktHK, exchangeRate.value, moneyType.value)
    // })
    // const marketValSum = computed(() => state.fund?.marketValue)
    const profitSum = computed(() => {
      const proHK = new Decimal(profitUS.value || 0)
        .mul(exchangeRateUS)
        .plus(profitHK.value || 0)
        .plus(new Decimal(profitCN.value || 0).mul(exchangeRateCN))
        .toString()
      return calcByMktStr(proHK, exchangeRate.value, moneyType.value)
    })
    // 重写现金明细
    const enableBalance = computed(() =>
      moneyType.value === 'HKD'
        ? state.fund?.enableBalance
        : state.fund?.enableBalance / exchangeRate.value
    )
    // HK市场下总资产
    // const totalAsset = computed(() => state.fund?.asset)
    // 根据市场计算值
    const dataByMkt = (item: { moneyType: string }, key: string) => {
      // 当前市场相同 当前市场的汇率
      const divRate = (rate: number) =>
        new Decimal(parseFloat(item[key]))
          .div(rate)
          .toFixed(2, Decimal.ROUND_DOWN)
      const mulRate = (rate: number) =>
        new Decimal(parseFloat(item[key]))
          .mul(rate)
          .toFixed(2, Decimal.ROUND_DOWN)
      const USToCNYRate = (rate1: number, rate2: number) =>
        new Decimal(parseFloat(item[key]))
          .mul(rate1)
          .div(rate2)
          .toFixed(2, Decimal.ROUND_DOWN)
      if (item.moneyType === 'HKD') {
        switch (moneyType.value) {
          case 'HKD':
            return item[key]
          case 'CNY':
            return divRate(exchangeRateCN)
          case 'USD':
            return divRate(exchangeRateUS)
          default:
            return item[key]
        }
      }
      if (item.moneyType === 'USD') {
        switch (moneyType.value) {
          case 'USD':
            return item[key]
          case 'CNY':
            return USToCNYRate(exchangeRateUS, exchangeRateCN)
          case 'HKD':
            return mulRate(exchangeRateUS)
          default:
            return item[key]
        }
      }
      if (item.moneyType === 'CNY') {
        switch (moneyType.value) {
          case 'CNY':
            return item[key]
          case 'USD':
            return USToCNYRate(exchangeRateCN, exchangeRateUS)
          case 'HKD':
            return mulRate(exchangeRateCN)
          default:
            return item[key]
        }
      }
    }
    const totalAsset = computed(() =>
      calcByMktStr(state.fund?.asset, exchangeRate.value, moneyType.value)
    )
    // 左侧表格
    const assetTableList = computed(() => {
      const result = ref()
      result.value = state.assetList.map((item: any) => {
        return {
          ...item,
          profit: dataByMkt(item, 'profit'),
          marketVal: dataByMkt(item, 'marketVal'),
          percent: !totalAsset.value
            ? '--'
            : parseFloat(
                new Decimal(dataByMkt(item, 'marketVal'))
                  .div(totalAsset.value)
                  .mul(100)
                  .toFixed(2, Decimal.ROUND_DOWN)
              ) + '%',
          stockNameGb:
            item.exchangeType === 'FUND' ? item.stockName : item.stockNameGb,
          stockCode: item.stockCode
        }
      })
      result.value = sortKey(result.value, 'percent')
      const marketValSum = computed(() => {
        const sum = result.value.reduce((v: any, s: any) => {
          return new Decimal(v).plus(s.marketVal).toNumber()
        }, 0)
        return new Decimal(sum).plus(fetchBalance.value || 0).toString()
      })
      // 现金
      const fundData = {
        stockNameGb: fundName,
        enablePos: '--',
        currentPos: '',
        marketVal: fetchBalance,
        currentPriceSum: '--',
        CostPriceSum: '',
        profit: '--',
        profitRate: '--',
        percent: percent
      }

      // 总计
      const totalData = {
        stockNameGb: totalName,
        enablePos: enableAmountSum,
        currentPos: currentAmountSum,
        marketVal: marketValSum,
        currentPriceSum: '--',
        CostPriceSum: '',
        profit: profitSum,
        profitRate: '',
        percent: '100%'
      }
      const arr = [...result.value, fundData, totalData]
      console.log('arr', arr)
      rowRecord.value = arr[0]
      isShowRowTable.value = true
      return arr
    })

    const nameList = computed(() => state.accList[0].bindInfo)

    const fundDetailTableData = computed(() =>
      state?.list.map((item: any) => {
        return {
          clientid: item.clientid,
          fetchBalance:
            moneyType.value === 'HKD'
              ? numFormat(item.fetchBalance, 2)
              : numFormat(
                  new Decimal(parseFloat(item.fetchBalance))
                    .div(exchangeRate.value)
                    .toNumber(),
                  2
                ),
          enableBalance:
            moneyType.value === 'HKD'
              ? numFormat(item.enableBalance, 2)
              : numFormat(
                  new Decimal(parseFloat(item.enableBalance))
                    .div(exchangeRate.value)
                    .toNumber(),
                  2
                ),
          nameCn: nameList.value.filter(
            (ele: any) => ele.account === item.clientid
          )[0]?.nameCn
        }
      })
    )
    // console.log(fundDetailTableData, 'fundDetailTableData')

    const res = computed(() =>
      filterStock(
        assetTableList.value[rowIndex.value]?.stockCode,
        state?.posList
      )
    )
    const assetDetailTableData = computed(() => {
      console.log('res.value', res.value)
      return res.value?.map((item: any) => {
        item.profitHK = new Decimal(
          parseFloat(
            item.currentPriceDisplay ? item.currentPriceDisplay : '0.0'
          )
        )
          .sub(item.costPrice)
          .mul(item.currentAmount)
          .toFixed(2, Decimal.ROUND_DOWN)
        // const profit =
        //   item.moneyType === moneyType.value
        //     ? new Decimal(
        //         parseFloat(
        //           item.currentPriceDisplay ? item.currentPriceDisplay : '0.0'
        //         )
        //       )
        //         .sub(item.costPrice)
        //         .mul(item.currentAmount)
        //         .toFixed(2, Decimal.ROUND_DOWN)
        //     : item.exchangeType === 'HK'
        //     ? new Decimal(
        //         parseFloat(
        //           item.currentPriceDisplay ? item.currentPriceDisplay : '0.0'
        //         )
        //       )
        //         .sub(item.costPrice)
        //         .mul(item.currentAmount)
        //         .div(exchangeRate.value)
        //         .toFixed(2, Decimal.ROUND_DOWN)
        //     : new Decimal(
        //         parseFloat(
        //           item.currentPriceDisplay ? item.currentPriceDisplay : '0.0'
        //         )
        //       )
        //         .sub(item.costPrice)
        //         .mul(item.currentAmount)
        //         .mul(exchangeRate.value)
        //         .toFixed(2, Decimal.ROUND_DOWN)
        const name = nameList.value.filter(
          (ele: { account: string }) => ele.account === item.fundAccount
        )
        return {
          ...item,
          profit: dataByMkt(item, 'profitHK'),
          nameCn: name.length
            ? name[0].nameCn ||
              name[0].englishFirstname + name[0].englishLastname
            : ''
        }
      })
    })

    // 资产概况表格高度
    // const assetTableHeight = computed(
    //   () => (assetTableList.value.length ?? 1) * 67 + 44
    // )
    // const popoverHeight = ref(assetTableHeight.value + 260)

    const isFund = computed(() =>
      assetTableList.value?.length <= 2
        ? true
        : rowIndex.value === assetTableList.value.length - 2 ||
          rowIndex.value === assetTableList.value.length - 1
    )
    const popoverHeight = computed(() =>
      isFund.value
        ? (fundDetailTableData.value?.length
            ? fundDetailTableData.value?.length
            : 4) *
            67 +
          235
        : (assetDetailTableData.value?.length
            ? assetDetailTableData.value?.length
            : 4) *
            67 +
          235
    )

    const rowClick = (row: any, column: any, event: Event) => {
      if (!row?.exchangeType) {
        isShowRowTable.value = false
        return
      }
      isShowRowTable.value = true
      rowIndex.value = row.index
      rowRecord.value = row
      let CCH = 77
      if (window.outerHeight <= 1080) {
        CCH = 77
      }
      if (window.outerHeight > 1080 && window.outerHeight <= 1440) {
        CCH = 60
      }
      arrowTop.value = CCH + row.index * 63
      console.log('arrowTop', arrowTop.value)
      if (row.index !== 0 && arrowTop.value > popoverHeight.value) {
        popoverTop.value = popoverHeight.value - (63 + row.index * 63) - 63
        console.log('popoverTop.value 1', popoverTop.value)
      } else {
        popoverTop.value = 0
      }
      console.log('popoverTop.value 2', popoverTop.value)
      // if (
      //   popoverTop.value + popoverRef.value?.offsetHeight >
      //   assetTableHeight.value
      // ) {
      //   console.log(
      //     `${row.index}行`,
      //     `offsettop${popoverRef.value.offsetWidth}`
      //   )
      //   popoverTop.value =
      //     assetTableHeight.value - popoverRef.value?.offsetHeight + 67
      // }
    }

    const bidData = (rowIndex: any) => {
      const bidAskList: any = []
      if (isFund.value) {
        fundDetailTableData.value.forEach((item: any) => {
          bidAskList.push({
            fundAccount: item.fundAccount,
            stockCode: assetTableList.value[rowIndex].stockCode,
            exchangeType: assetTableList.value[rowIndex].exchangeType,
            entrustPrice: assetTableList.value[rowIndex].currentPriceDisplay,
            stockNameGb: assetTableList.value[rowIndex].stockNameGb
          })
        })
      } else {
        assetDetailTableData.value.forEach((item: any) => {
          bidAskList.push({
            fundAccount: item.fundAccount,
            stockCode: item.stockCode,
            exchangeType: item.exchangeType,
            entrustPrice: item.currentPriceDisplay,
            stockNameGb: item.stockNameGb
          })
        })
      }
      return bidAskList
    }

    const bid = (rowIndex: number) => {
      const arr = _.uniqWith(bidData(rowIndex), _.isEqual)
      console.log('买入买入买入买入买入', arr)
      router.push({
        name: 'BulkTrading',
        params: {
          bidAskList: JSON.stringify(arr)
        }
      })
    }
    // const ask = (rowIndex: number) => {
    //   console.log('卖出', rowIndex)
    //   const arr = _.uniqWith(bidData(rowIndex), _.isEqual)
    //   console.log(arr, '卖出卖出')
    //   router.push({
    //     name: 'BulkTrading',
    //     params: {
    //       bidAskList: JSON.stringify(arr)
    //     }
    //   })
    // }
    return {
      bid,
      enableBalance,
      rowIndex,
      rowRecord,
      assetTableHead,
      assetDetailTableData,
      assetDetailTableHead,
      fundDetailTableHead,
      fundDetailTableData,
      popoverRef,
      popoverTop,
      arrowTop,
      // arrowRight,
      popoverHeight,
      assetTableList,
      rowClick,
      isFund,
      numFormat,
      profitColor,
      isShowRowTable
    }
  }
})
