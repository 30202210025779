
import { reactive, toRefs } from 'vue'
import PanelGroup from './components/PanelGroup.vue'
import { tHeaderList } from './hooks/data'
import AssetOverview from './components/AssetOverview.vue'
import { useStore } from 'vuex'
import { CommonMutationTypes } from '@/store/modules/common/mutation-types'
import dayjs from 'dayjs'
import { useCommonInfo } from '@/hooks/common'
import useData from './hooks/useData'
import useOrder from './hooks/useOrder'

export default {
  components: {
    PanelGroup,
    AssetOverview
  },
  setup() {
    const store = useStore()
    const {
      fundList,
      fList,
      posList,
      accList,
      assetList,
      orderList,
      summaryList,
      refetch,
      loading
    } = useData()
    const { orderLoading, orderInfotableData, orderRefetch } = useOrder(accList)
    const { moneyType } = useCommonInfo()
    const state = reactive({
      moneyOptions: [
        { text: '人民币', val: 'CNY', mkt: 'A' },
        { text: '港币', val: 'HKD', mkt: 'HK' },
        { text: '美元', val: 'USD', mkt: 'US' }
      ],
      currency: '',
      exchangeType: 'HK',
      dialogVisible: false,
      contentLoading: true,
      confirmLoading: false
    })
    state.currency = state.moneyOptions.filter(
      item => item.val === moneyType.value
    )[0]?.text
    /*
     *@Description: 切换币种
     *@param: {*}
     *@return: {*}
     */
    const changeCurrency = (item: {
      val: string
      text: string
      mkt: string
    }) => {
      state.currency = item?.text
      state.exchangeType = item.mkt
      store.commit(CommonMutationTypes.SET_MONEY_TYPE, item.val)
    }

    return {
      ...toRefs(state),
      changeCurrency,
      orderList,
      tHeaderList,
      orderInfotableData,
      dayjs,
      fundList,
      fList,
      assetList,
      posList,
      accList,
      summaryList,
      refetch,
      loading,
      orderLoading,
      orderRefetch
    }
  }
}
