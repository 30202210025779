import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ccc708ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "asset-view" }
const _hoisted_2 = { class: "asset-table" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "text-right total-balance" }
const _hoisted_5 = { class: "total-profit" }
const _hoisted_6 = {
  key: 0,
  class: "btns"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FargoTable = _resolveComponent("FargoTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FargoTable, {
        list: _ctx.assetTableList,
        "onUpdate:list": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.assetTableList) = $event)),
        "t-header-list": _ctx.assetTableHead,
        onRowClick: _ctx.rowClick,
        isClick: true,
        tableLoading: _ctx.loading,
        sortPercent: true
      }, {
        default: _withCtx(() => [
          (_ctx.isShowRowTable)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "popover-arrow",
                style: _normalizeStyle({ top: _ctx.arrowTop + 'px' })
              }, null, 4))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["list", "t-header-list", "onRowClick", "tableLoading"])
    ]),
    (_ctx.isShowRowTable)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "popover",
          ref: "popoverRef",
          style: _normalizeStyle({ bottom: _ctx.popoverTop + 'px', height: _ctx.popoverHeight + 'px' })
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.isFund ? "现金" : "持仓") + "明细", 1),
          _createVNode(_component_FargoTable, {
            list: _ctx.assetDetailTableData,
            "onUpdate:list": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.assetDetailTableData) = $event)),
            "t-header-list": _ctx.assetDetailTableHead,
            tableLoading: _ctx.loading
          }, null, 8, ["list", "t-header-list", "tableLoading"]),
          _withDirectives(_createElementVNode("div", _hoisted_4, [
            _createTextVNode(" 总购买力 "),
            _createElementVNode("span", null, _toDisplayString(_ctx.numFormat(_ctx.enableBalance, 2)), 1)
          ], 512), [
            [_vShow, _ctx.isFund && !_ctx.loading]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_5, [
            _createTextVNode(" 总盈亏 "),
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.profitColor(_ctx.assetTableList[_ctx.rowIndex]?.profit))
            }, _toDisplayString(_ctx.numFormat(Number(_ctx.assetTableList[_ctx.rowIndex]?.profit), 2)), 3)
          ], 512), [
            [_vShow, !_ctx.isFund && !_ctx.loading]
          ]),
          (
          _ctx.rowIndex !== _ctx.assetTableList.length - 1 &&
          _ctx.rowIndex !== _ctx.assetTableList.length - 2 &&
          _ctx.rowRecord.exchangeType !== 'FUND'
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: "btn-ask btn-trade",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.bid(_ctx.rowIndex)))
                }, "买入"),
                _createElementVNode("div", {
                  class: "btn-bid btn-trade",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.bid(_ctx.rowIndex)))
                }, "卖出")
              ]))
            : _createCommentVNode("", true)
        ], 4))
      : _createCommentVNode("", true)
  ]))
}