export const tHeaderList = Object.freeze([
  {
    label: '下单人',
    prop: 'operatorAccount',
    type: 'color',
    width: '120px'
  },
  { label: '时间', prop: 'createTime', type: 'time', width: '' },
  { label: '方向', prop: 'entrustBs', type: 'entrustBs', width: '' },
  {
    label: '产品',
    prop: 'stock',
    type: 'twoLinesNewsLines',
    width: '220px',
    propList: [
      { prop: 'stockName', icon: 'exchangeType', class: 'lines-stock-name' },
      { prop: 'stockCode', class: 'lines-stock-code' },
      { prop: 'exchangeType', class: 'lines-stock-type' }
    ]
  },
  { label: '价格', prop: 'entrustPrice', type: 'price', width: '' },
  { label: '总数量', prop: 'entrustAmount', type: 'permillage', width: '' },
  { label: '下单账户数', prop: 'entrustFundcount', type: '', width: '' },
  {
    label: '状态',
    prop: 'state',
    type: 'state',
    // btnType: 'status',
    width: ''
  },
  {
    label: '操作',
    prop: 'action',
    type: 'setting',
    crossFlag: true,
    btnType: 'detail',
    setting: [
      {
        name: '查看详情',
        btnType: 'detail',
        color: 'text',
        type: 'show',
        permission: []
      }
    ],
    btns: [
      {
        name: '一键撤单',
        btnType: 'cancel',
        color: 'text',
        typeReal: 'state', // 显示控制的字段
        showValue: [0, 3] // 具体字段显示的值
      },
      {
        name: '一键改单',
        btnType: 'update',
        color: 'text',
        typeReal: 'state', // 显示控制的字段
        showValue: [0, 3] // 具体字段显示的值
      }
    ],
    width: ''
  }
])

export const assetTableHead = Object.freeze([
  {
    label: '名称/代码',
    prop: 'stock',
    type: 'twoLines',
    class: 'left',
    // width: '220px',
    tooltip: true,
    propList: [
      { prop: 'stockNameGb' },
      { prop: 'stockCode', icon: 'exchangeType' }
    ]
  },
  {
    label: '可用/持仓',
    prop: 'position',
    type: 'twoLines',
    width: '100px',
    propList: [{ prop: 'enablePos' }, { prop: 'currentPos' }]
  },
  {
    label: '市值',
    prop: 'marketVal',
    type: 'permillage',
    width: '110px'
  },
  {
    label: '现价/成本',
    prop: 'price',
    type: 'twoLines',
    width: '110px',
    propList: [{ prop: 'currentPriceSum' }, { prop: 'CostPriceSum' }]
  },
  {
    label: '盈亏',
    prop: 'profit',
    width: '110px',
    propList: [{ prop: 'profit' }, { prop: 'profitPercent' }]
  },
  { label: '仓位占比', prop: 'percent', type: '', width: '110px' }
])

export const assetDetailTableHead = Object.freeze([
  {
    label: '姓名/账户',
    prop: 'name',
    type: 'nameCn',
    width: '',
    propList: [{ prop: 'nameCn' }, { prop: 'fundAccount' }]
  },
  { label: '持仓数量', prop: 'currentAmount', type: '', width: '' },
  {
    label: '成本价',
    prop: 'costPrice',
    // type: 'price',
    width: ''
  },
  { label: '盈亏', prop: 'profit', type: 'color', width: '' }
])
export const fundDetailTableHead = Object.freeze([
  {
    label: '姓名/账户',
    prop: 'name',
    type: 'multiText',
    width: '',
    propList: [{ prop: 'nameCn' }, { prop: 'clientid' }]
  },
  { label: '购买力', prop: 'enableBalance', type: '', width: '' },
  {
    label: '现金可取',
    prop: 'fetchBalance',
    type: '',
    width: ''
  }
])
